/**
 * The base URL for the application.
 * If the environment variable CI is set, it uses the canonical URL from the environment.
 * Otherwise, it uses the local development URL.
 */
export const BASE_URL =
  process.env.NEXT_PUBLIC_CANONICAL_URL ?? "http://localhost:3000";

/**
 * The base URL for the API.
 */
export const NIKE_URL = process.env.NEXT_PUBLIC_NIKE_URL ?? "";
export const TV_URL = process.env.NEXT_PUBLIC_TV_URL ?? "";
export const TV_MANAGER_URL = process.env.NEXT_PUBLIC_TV_MANAGER_URL ?? "";
