import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/apps/web/components/markdown/HTMLMarkdown.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["DemoComponent"] */ "/builds/tradingvalley-frontend/growin/apps/web/features/event/winwin-gpt-wait-list/components/DemoComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AISectorSection"] */ "/builds/tradingvalley-frontend/growin/apps/web/features/stock-mining/components/AISectorSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExampleSection"] */ "/builds/tradingvalley-frontend/growin/apps/web/features/stock-mining/components/ExampleSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureSection"] */ "/builds/tradingvalley-frontend/growin/apps/web/features/stock-mining/components/FeatureSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScoreDetails"] */ "/builds/tradingvalley-frontend/growin/apps/web/features/stock-mining/components/score-section/ScoreDetails.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/tradingvalley-frontend/growin/apps/web/features/stock-mining/components/style-section/ClientCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/apps/web/public/stock-mining/background.png");
;
import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/apps/web/public/stock-mining/background.webp");
;
import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/apps/web/public/stock-mining/banner/brain-logo-en.webp");
;
import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/apps/web/public/stock-mining/banner/brain-logo-zh.webp");
;
import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/apps/web/public/tradingview/tradingview.svg");
;
import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/tradingvalley-frontend/growin/node_modules/react-wrap-balancer/dist/index.mjs");
