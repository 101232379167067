import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

import { LoadingIndicator } from "./LoadingIndicator";

export type ButtonProps = ComponentProps<"button"> & {
  variant?: "primary" | "secondary";
  isLoading?: boolean;
};

export const Button = ({
  variant = "primary",
  children,
  className,
  isLoading,
  ...props
}: ButtonProps) => (
  <button
    type="button"
    {...props}
    className={twMerge(
      "focus-visible:outline-sky-60 relative rounded-md bg-sky-700 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm transition hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-sky-700",
      variant === "secondary" &&
        "rounded-md bg-white px-3.5 py-2.5 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-white",
      isLoading && "pointer-events-none text-opacity-0 opacity-75",
      className
    )}
  >
    {children}
    {isLoading && (
      <LoadingIndicator
        className={twMerge(
          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
          variant === "secondary" ? "text-gray-900" : "text-white"
        )}
      />
    )}
  </button>
);
