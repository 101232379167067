import { Select as MUISelect, MenuItem } from "@mui/material";
import { twMerge } from "tailwind-merge";

import { GrowinColors } from "@/colors";

import { SelectProps } from "./SelectProps";

const Select = ({
  data,
  value = data[0],
  itemAdornments = [],
  itemAdornmentsPosition = "end",
  onValueChange,
  menuItemClassName,
  ...props
}: SelectProps) => (
  <MUISelect
    value={value}
    sx={{
      ".MuiSelect-select": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        flexDirection:
          itemAdornmentsPosition === "start" ? "row-reverse" : "row",
      },
      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #BDBDBD",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid",
        borderColor: GrowinColors["data-primary"],
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid",
        borderColor: GrowinColors["data-primary"],
      },
      ...props.sx,
    }}
    onChange={(e) => {
      props.onChange?.(e, null);
      onValueChange(e.target.value as string);
    }}
    {...props}
    className={twMerge("text-sm", props.className)}
  >
    {data.map((value, index) => (
      <MenuItem
        key={index}
        value={value}
        dense
        className={twMerge(
          "flex w-full items-center justify-between text-sm",
          menuItemClassName,
          itemAdornmentsPosition === "start" ? "flex-row-reverse" : ""
        )}
      >
        {value}
        {itemAdornments[index]}
      </MenuItem>
    ))}
  </MUISelect>
);

export default Select;
