import { useRef } from "react";
import Slider from "react-slick";
import useMeasure from "react-use-measure";
import { twMerge } from "tailwind-merge";

import { ChevronLeft } from "@/icon/shared";
import { ConditionWrapper } from "@/utils/ConditionWrapper";

import style from "./AISectorCarousel.module.scss";
import { AISectorCarouselProps } from "./AISectorCarouselProps";
import { AISectorTable } from "./AISectorTable";

export const AISectorCarousel = ({
  data,
  lang = "zh",
}: AISectorCarouselProps) => {
  const slickRef = useRef<Slider>(null);
  const [ref, { width }] = useMeasure();

  return (
    <div
      className={twMerge(
        "flex max-w-[1140px] items-center justify-center",
        width >= 500 ? "gap-8" : "gap-3"
      )}
      ref={ref}
    >
      {width >= 500 && (
        <ArrowButton
          direction="left"
          onClick={() => {
            slickRef.current?.slickPrev();
          }}
        />
      )}
      <RankingHeader />
      <ConditionWrapper
        wrapper={(children) => (
          <div className="w-full max-w-[250px]">{children}</div>
        )}
        showWrapper={width < 500}
      >
        <Slider
          ref={slickRef}
          dots={true}
          arrows={false}
          infinite={false}
          swipeToSlide
          slidesToShow={1}
          speed={500}
          variableWidth={width >= 500}
          adaptiveHeight={width >= 500}
          // To wrap carousel in flex box
          // https://github.com/akiran/react-slick/issues/1320#issuecomment-427359800
          className="min-h-0 min-w-0 pb-4"
          dotsClass={`slick-dots ${style.dots}`}
        >
          {data.map((props) => (
            <AISectorTable
              {...props}
              key={props.mainCategory}
              maxWidth={width > 330 ? 300 : 250}
              lang={lang}
            />
          ))}
        </Slider>
      </ConditionWrapper>
      {width >= 500 && (
        <ArrowButton
          direction="right"
          onClick={() => {
            slickRef.current?.slickNext();
          }}
        />
      )}
    </div>
  );
};

const ArrowButton = ({
  direction,
  onClick,
}: {
  direction: "left" | "right";
  onClick: React.MouseEventHandler<HTMLSpanElement>;
}) => (
  <span
    role="button"
    aria-label={direction === "left" ? "previous-button" : "next-button"}
    className={`shrink-0 cursor-pointer transition-transform hover:scale-105 ${
      direction === "right" ? "rotate-180" : ""
    }`}
    onClick={onClick}
  >
    <ChevronLeft />
  </span>
);

const RankingHeader = () => (
  <div className="mt-2 flex h-[305px] max-w-[12px] shrink-0 select-none flex-col items-center justify-between pt-10">
    {[1, 2, 3, 4, 5].map((i) => (
      <p key={i}>{i}</p>
    ))}
  </div>
);
