import { Oriented } from "@/data/constants/oriented";
import koChipEN from "@/public/stock-mining/example/preview/ko/chip-en.jpg";
import koChip from "@/public/stock-mining/example/preview/ko/chip.png";
import koDividendEN from "@/public/stock-mining/example/preview/ko/dividend-en.jpg";
import koDividend from "@/public/stock-mining/example/preview/ko/dividend.png";
import koSwingEN from "@/public/stock-mining/example/preview/ko/swing-en.jpg";
import koSwing from "@/public/stock-mining/example/preview/ko/swing.png";
import koTrendEN from "@/public/stock-mining/example/preview/ko/trend-en.jpg";
import koTrend from "@/public/stock-mining/example/preview/ko/trend.png";
import koValueEN from "@/public/stock-mining/example/preview/ko/value-en.jpg";
import koValue from "@/public/stock-mining/example/preview/ko/value.png";
import tslaChipEN from "@/public/stock-mining/example/preview/tsla/chip-en.jpg";
import tslaChip from "@/public/stock-mining/example/preview/tsla/chip.png";
import tslaDividendEN from "@/public/stock-mining/example/preview/tsla/dividend-en.jpg";
import tslaDividend from "@/public/stock-mining/example/preview/tsla/dividend.png";
import tslaSwingEN from "@/public/stock-mining/example/preview/tsla/swing-en.jpg";
import tslaSwing from "@/public/stock-mining/example/preview/tsla/swing.png";
import tslaTrendEN from "@/public/stock-mining/example/preview/tsla/trend-en.jpg";
import tslaTrend from "@/public/stock-mining/example/preview/tsla/trend.png";
import tslaValueEN from "@/public/stock-mining/example/preview/tsla/value-en.jpg";
import tslaValue from "@/public/stock-mining/example/preview/tsla/value.png";
import xomChipEN from "@/public/stock-mining/example/preview/xom/chip-en.jpg";
import xomChip from "@/public/stock-mining/example/preview/xom/chip.png";
import xomDividendEN from "@/public/stock-mining/example/preview/xom/dividend-en.jpg";
import xomDividend from "@/public/stock-mining/example/preview/xom/dividend.png";
import xomSwingEN from "@/public/stock-mining/example/preview/xom/swing-en.jpg";
import xomSwing from "@/public/stock-mining/example/preview/xom/swing.png";
import xomTrendEN from "@/public/stock-mining/example/preview/xom/trend-en.jpg";
import xomTrend from "@/public/stock-mining/example/preview/xom/trend.png";
import xomValueEN from "@/public/stock-mining/example/preview/xom/value-en.jpg";
import xomValue from "@/public/stock-mining/example/preview/xom/value.png";

export interface CompanyInfo {
  symbol: string;
  name: string;
  score: {
    value: number;
    trend: number;
    swing: number;
    chip: number;
    dividend: number;
  };
  focus: Oriented;
  updatedAt: string;
}

export const CompanyData: CompanyInfo[] = [
  {
    symbol: "tsla",
    name: "Tesla",
    score: { value: 5, trend: 2, swing: 3, chip: 3, dividend: 1 },
    focus: "value",
    updatedAt: "2022/05/31",
  },
  {
    symbol: "ko",
    name: "Coca-Cola",
    score: { value: 5, trend: 3, swing: 3, chip: 4, dividend: 4 },
    focus: "chip",
    updatedAt: "2022 Q1",
  },
  {
    symbol: "xom",
    name: "Exxon Mobil",
    score: { value: 5, trend: 4, swing: 1, chip: 3, dividend: 4 },
    focus: "trend",
    updatedAt: "2022/06/24",
  },
];

export const OrientedDescription = {
  zh: {
    value:
      "透過分析基本面的綜合財報資訊（如：資金運用、盈利能力、股東權益等）給予價值力道評分。",
    trend:
      "Growin 研發獨家的量化指標，於技術面判斷標的的趨勢走向及動能，給予趨勢力道評分。",
    swing:
      "Growin 的 AI 波段評分是要幫助投資人找到波段開始前的時機，以判斷要入場或出場。",
    chip: "透過 13F 持倉報告分析，協助投資人參考大型機構的資金佈局，以站在巨人的肩膀上找尋投資機會。",
    dividend:
      "股利評分參考企業過去的股利政策、每年殖利率與填息狀況等統整編制而成。",
  },
  en: {
    value:
      "Analyzing comprehensive financial statements (e.g., capital utilization, profitability, etc.) for value scoring.",
    trend:
      "Our proprietary indicators assess stock price and momentum from a technical perspective for trend scoring.",
    swing:
      "Our AI swing trading scoring helps traders find pre-trend entry or exit points, enhancing trading returns.",
    chip: "Leverage 13F reports for insights into institutional positioning, accessing investment opportunities from whales.",
    dividend:
      "Dividend scoring based on past dividend policies, ave yield, and dividend fill rate can enhance a buy-and-hold strategy.",
  },
};

export const ImageData = {
  ko: {
    zh: {
      value: koValue,
      trend: koTrend,
      swing: koSwing,
      chip: koChip,
      dividend: koDividend,
    },
    en: {
      value: koValueEN,
      trend: koTrendEN,
      swing: koSwingEN,
      chip: koChipEN,
      dividend: koDividendEN,
    },
  },
  tsla: {
    zh: {
      value: tslaValue,
      trend: tslaTrend,
      swing: tslaSwing,
      chip: tslaChip,
      dividend: tslaDividend,
    },
    en: {
      value: tslaValueEN,
      trend: tslaTrendEN,
      swing: tslaSwingEN,
      chip: tslaChipEN,
      dividend: tslaDividendEN,
    },
  },
  xom: {
    zh: {
      value: xomValue,
      trend: xomTrend,
      swing: xomSwing,
      chip: xomChip,
      dividend: xomDividend,
    },
    en: {
      value: xomValueEN,
      trend: xomTrendEN,
      swing: xomSwingEN,
      chip: xomChipEN,
      dividend: xomDividendEN,
    },
  },
};
