import { Lang } from "@/i18n-config";

export const getPlaceHolders = (lang: Lang) =>
  ({
    zh: [
      "價值型 投資人",
      "趨勢交易 投資人",
      "波段交易 投資人",
      "籌碼 達人",
      "存股 達人",
    ],
    en: [
      "Value Investing",
      "Trend Following",
      "Swing Trading",
      "Whale Following",
      "Dividend Investing",
    ],
  }[lang]);

export const getItems = (lang: Lang) =>
  ({
    zh: ["價值型", "趨勢型", "波段型", "籌碼型", "股利型"],
    en: [
      "Value Investing",
      "Trend Following",
      "Swing Trading",
      "Whale Following",
      "Dividend Investing",
    ],
  }[lang]);

export const getTitles = (lang: Lang) =>
  ({
    zh: [
      "價值型投資人",
      "趨勢型投資人",
      "波段型投資人",
      "籌碼型投資人",
      "股利型投資人",
    ],
    en: [
      "Value Investing",
      "Trend Following",
      "Swing Trading",
      "Whale Following",
      "Dividend Investing",
    ],
  }[lang]);

export const getDescriptions = (lang: Lang) =>
  ({
    zh: [
      "你具有洞悉股票價值的眼光，擁有長期等待的耐心。專注投資財務穩健、持續穩定獲利的股票，當股價低於認定的價值時勇於逢低加碼",
      "你喜歡擁有動能的股票並擅長沿著趨勢行情操作。經驗豐富的你懂得在趨勢不明時觀望，更明白投資基本面有潛力的股票能提升你順勢交易的勝率",
      "你喜愛低買高賣，在股價高低起伏的交易環境中如魚得水。而當股票兼具好的價值與高度籌碼累積時，你更能抓到大的波段行情",
      "你喜愛站在巨人的肩膀上找尋投資機會，挖掘巨鯨們靜靜佈局的股票。儘管如此，你明白跟隨他們的腳步需要搭配耐心，培養自己的進出策略。",
      "你追求長期穩健的投資，尋覓的標的性質除了財務穩健且具備成長性，若其殖利率高但波動小，亦可在股利面向得到穩定的獲利",
    ],
    en: [
      "With insightful view in stock value, always invest in financially stable stocks for consistent profits and add more when price is undervalued.",
      "Momentum stocks expert. Wait in uncertainty, trade with fundamentals for higher success in trend trading.",
      "Expert in swing trading, capturing big moves with value stocks and high accumulation opportunities.",
      "Enjoy seeking investment opportunities and uncover stocks quietly positioned by institutional investors.",
      "You pursue long-term stable investments, seeking targets that have growth potential, and high dividend yields with low volatility.",
    ],
  }[lang]);

export const getTags = (lang: Lang) =>
  ({
    zh: [
      ["價值：4", "股利：2"],
      ["趨勢：4", "價值：3"],
      ["波段：4", "價值：3", "籌碼：3"],
      ["籌碼：4"],
      ["股利：4", "波段：3"],
    ],
    en: [
      ["Value: 4", "Dividend: 2"],
      ["Trend: 4", "Value: 3"],
      ["Swing Trading: 4", "Value: 3", "Whale Interest: 3"],
      ["Whale Interest: 4"],
      ["Dividend: 4", "Swing Trading: 3"],
    ],
  }[lang]);
