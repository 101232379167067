import { ComponentProps, ComponentRef, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export const Select = forwardRef<
  ComponentRef<"select">,
  ComponentProps<"select"> & {
    options: string[];
    isError?: boolean;
    placeholder?: string;
  }
>(
  (
    { className, placeholder, options, value, defaultValue, isError, ...props },
    ref
  ) => {
    return (
      <select
        ref={ref}
        role="combobox"
        {...props}
        className={twMerge(
          "block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-zinc-300 invalid:text-gray-400 focus:ring-2 focus:ring-sky-700 sm:text-sm sm:leading-6",
          isError &&
            "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500",
          className
        )}
        value={value}
        defaultValue={value !== undefined ? undefined : defaultValue}
      >
        {placeholder && (
          <option key={placeholder} value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
);

Select.displayName = "Select";
