const GrowinColors = {
  "growin-primary-light": "#3AD29F",
  "growin-primary": "#2ABA77",
  "growin-primary-dark": "#20995D",
  "data-primary-light": "#A1C8FC",
  "data-primary": "#5A8FD6",
  "data-primary-dark": "#225A9F",
  "normal-text-color": "#202020",
  "inactive-text-color": "#797979",
  "disable-text-color": "#939393",
  "input-border-color": "#BDBDBD",
  "disable-button-color": "#EEEEEE",
  "dashboard-color": "#F9FBFC",
  "table-border-color": "#CED3DF",
  "table-header-color": "#EAF1FA",
  rise: "#2ABA77",
  fall: "#F23645",
  "power-1": "#BDBDBD",
  "power-2": "#FAA1A4",
  "power-3": "#FF9800",
  "power-4": "#F23645",
  "level-1": "#F4C854",
  "level-2": "#FFEC8F",
  "level-3": "#E3E9A0",
  "level-4": "#CCEFED",
  "level-5": "#14B3BC",
  "moving-average-0": "#225A9F",
  "moving-average-1": "#6CB9AA",
  "moving-average-2": "#C4EFDD",
  "moving-average-3": "#FFF2CF",
  "moving-average-4": "#FFDF89",
  "moving-average-5": "#F8C1C3",
  "moving-average-6": "#F76D72",
  "power-squeeze-positive-asc": "#b4eb9e",
  "power-squeeze-positive-desc": "#8ed075",
  "power-squeeze-negative-asc": "#96e1f7",
  "power-squeeze-negative-desc": "#5cadc7",
  "surfing-trend-positive-asc": "#a8f0d2",
  "surfing-trend-positive-desc": "#22ab94",
  "surfing-trend-negative-asc": "#F88e92",
  "surfing-trend-negative-desc": "#D9363d",
  "stock-mining": "#58C1D7",
  "stock-mining-dark": "#2D80AF",
  "stock-mining-light": "#C4F5FF",
  "stock-mining-bg": "#DEF3F7",
  "stock-mining-bg-light": "#F7FCFD",
};
export default GrowinColors;
