import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { twMerge } from "tailwind-merge";

import { AccordionItemProps } from "./AccordionProps";

const AccordionItem = ({
  expanded,
  onClick,
  icon,
  iconAnchor,
  title,
  content,
  showActiveLine,
  activeLineColor = "linear-gradient(90deg, rgb(88, 193, 215) 0%, rgb(45, 128, 175) 100%)",
  className,
  childrenLabel,
}: AccordionItemProps) => (
  <Accordion
    expanded={expanded}
    className={twMerge("shadow-none", className)}
    disableGutters
    sx={{
      "&.MuiAccordion-root:before": {
        opacity: showActiveLine ? 0 : 1,
        backgroundColor: "#BDBDBD",
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        transform: "rotate(-90deg)",
      },
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(0deg)",
      },
    }}
  >
    <div>
      {showActiveLine && (
        <div
          className="w-full transition-all duration-200 ease-in-out"
          style={{
            height: `${expanded ? "5px" : "1px"}`,
            background: `${
              expanded
                ? activeLineColor
                : "linear-gradient(90deg, rgba(189,189,189,1) 0%, rgba(189,189,189,1) 100%)"
            }`,
          }}
          aria-label="active-line"
        />
      )}
      <AccordionSummary
        className="flex flex-row-reverse gap-4 px-0 text-sm font-semibold md:text-base"
        expandIcon={icon}
        style={{
          flexDirection: iconAnchor === "left" ? "row-reverse" : "row",
        }}
        onClick={onClick}
        aria-label={childrenLabel}
        aria-expanded={expanded}
      >
        {title}
      </AccordionSummary>
    </div>
    <AccordionDetails
      className={twMerge(
        "px-0 pt-0 text-sm font-normal",
        iconAnchor === "right" ? "pr-6" : ""
      )}
    >
      {content}
    </AccordionDetails>
  </Accordion>
);

export const MemoAccordionItem = React.memo(AccordionItem);
