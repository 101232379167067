import { twMerge } from "tailwind-merge";

import type { AISectorTableProps } from "./AISectorCarouselProps";
import { AISectorItem } from "./AISectorItem";

export const AISectorTable = ({
  oriented,
  mainCategory,
  avgScore,
  items,
  maxWidth,
  lang = "zh",
}: AISectorTableProps) => (
  <div className="mx-auto flex w-full flex-col px-3" style={{ maxWidth }}>
    <p className="flex gap-3 truncate p-2 font-bold text-sky-700">
      <span>{mainCategory}</span>({lang === "zh" ? "平均分數" : "Avg"}{" "}
      {avgScore.toFixed(2)})
    </p>
    <div className="divide-y rounded-xl border border-[#B0CFE1] bg-white px-2">
      <div
        className={twMerge(
          "flex items-center justify-between truncate py-2 pl-2 text-xs text-[#797979]",
          lang === "zh" ? "pr-5" : "pr-2"
        )}
      >
        <p className="text-xs">{lang === "zh" ? "標的" : "Symbol"}</p>
        <div className={twMerge("flex", lang === "zh" ? "gap-8" : "gap-2")}>
          <p className="text-xs">
            {oriented.includes(" ")
              ? oriented
                  .split(" ")
                  .map((word) => word[0])
                  .join("")
              : oriented}
            {lang === "zh" ? "分數" : " Score"}
          </p>
          <p className="text-xs">{lang === "zh" ? "市值" : "Mkt. Cap"}</p>
        </div>
      </div>
      {items.map((itemProps) => (
        <div key={itemProps.symbol} className="py-[2px] last-of-type:pb-1">
          <AISectorItem {...itemProps} />
        </div>
      ))}
    </div>
  </div>
);
