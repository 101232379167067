import React from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart as RC,
  Radar,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";

import { TickInfoProps } from "@/analysis/radar-chart";
import { GrowinColors } from "@/colors";

import { HoverRadarChartProps } from "./HoverRadarChartProps";

export const DEFAULT_TICK_INFO = {
  passiveNameColor: "fill-inactive-text-color",
  activeNameColor: "fill-normal-text-color",
  focusNameColor: "fill-stock-mining-dark",
};

export const DEFAULT_RADAR_INFO = {
  dotFill: GrowinColors["data-primary-dark"],
  dotRadius: 3,
  gradientEnd: GrowinColors["stock-mining-dark"],
  gradientStart: GrowinColors["stock-mining-light"],
};

interface TickProps {
  payload: { value: string };
  x: number;
  y: number;
  cx: number;
  cy: number;
  textAnchor: string;
}

function HoverRadarChart({
  data,
  onChange,
  tickInfo = DEFAULT_TICK_INFO,
  radarInfo = DEFAULT_RADAR_INFO,
  polarGridColor = "#FFFFFF",
  minValue = 0,
  maxValue = 5,
  focusValue = "",
  lang = "zh",
}: HoverRadarChartProps) {
  const { dotFill, dotRadius, gradientStart, gradientEnd } = radarInfo;
  const RadarData = handleRadarData(data);

  const handleMouseOver = (e: CategoricalChartState) => {
    if (!!e.activeLabel && e.activeLabel !== focusValue)
      onChange?.(e.activeLabel);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RC
        data={RadarData}
        onMouseMove={handleMouseOver}
        margin={{ left: lang === "zh" ? 100 : 160 }}
        width={360}
        height={360}
      >
        <PolarGrid strokeWidth={2} stroke={polarGridColor} />
        <PolarAngleAxis
          dataKey="name"
          tick={(tickProps: TickProps) =>
            handleTick({
              data,
              tickProps,
              tickInfo,
              focusValue,
              onHover: onChange,
              lang,
            })
          }
        />
        <PolarRadiusAxis
          domain={[minValue, maxValue]}
          tickCount={maxValue + 1}
          tick={false}
          axisLine={false}
        />
        <Radar
          dataKey="value"
          fill="url('#gradient')"
          fillOpacity="0.6"
          dot={{
            fill: dotFill,
            strokeWidth: 1,
            r: dotRadius,
            strokeDasharray: "",
          }}
        />
        <Tooltip content={<></>} />
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor={gradientStart} />
            <stop offset="95%" stopColor={gradientEnd} />
          </linearGradient>
        </defs>
      </RC>
    </ResponsiveContainer>
  );
}

const handleTick = ({
  data,
  tickProps: { x, y, cx, cy, payload, textAnchor },
  tickInfo,
  focusValue,
  onHover,
  lang,
}: {
  data: {
    [index: string]: number;
  };
  tickProps: TickProps;
  tickInfo: TickInfoProps;
  focusValue: string;
  onHover: (label: string) => void;
  lang: string;
}) => {
  const passiveNameColor = tickInfo.passiveNameColor;
  const activeNameColor = tickInfo.activeNameColor;
  const selectedNameColor = tickInfo.focusNameColor;
  const selected = focusValue === payload.value;
  const fontSize =
    lang === "zh"
      ? selected
        ? "text-base md:text-xl"
        : "text-sm md:text-base"
      : selected
      ? "text-base"
      : "text-sm";
  const fontWeight = selected ? "font-bold" : "font-medium";
  const textColor = data[payload.value]
    ? selected
      ? selectedNameColor
      : activeNameColor
    : passiveNameColor;

  return (
    <svg
      id={`radar-tick-${payload.value}`}
      onMouseEnter={() => {
        onHover(payload.value);
      }}
      textAnchor={textAnchor}
    >
      <text
        y={y + (y - cy) / 10 - 15}
        x={x + (x - cx) / 10}
        className={`${fontSize} ${fontWeight} ${textColor}`}
      >
        {payload.value.split(" ").map((word: string, i: number) => (
          <tspan dy="1.2em" x={x + (x - cx) / 10} key={i}>
            {word}
            {i === payload.value?.split(" ").length - 1 &&
              `: ${data[payload.value]}`}
          </tspan>
        ))}
      </text>
    </svg>
  );
};

const handleRadarData = (data: { [index: string]: number }) =>
  Object.keys(data).map((key) => ({
    name: key,
    value: data[key],
  }));

export default React.memo(HoverRadarChart);
