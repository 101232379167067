import { DialogProps } from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import dynamic from "next/dynamic";
import { useCallback, useMemo, useState } from "react";

import { CrossIcon } from "ui/icon/shared";

const Dialog = dynamic(() => import("@mui/material/Dialog"), { ssr: false });

export const useDialog = ({
  open = false,
  isMobileFullScreen = false,
} = {}) => {
  const [isOpen, setIsOpen] = useState(open);

  const openDialog = useCallback(() => setIsOpen(true), []);
  const closeDialog = useCallback(() => setIsOpen(false), []);

  const GlobalDialog = useMemo(() => {
    type Params = Omit<Partial<DialogProps>, "title"> & {
      title?: React.ReactNode;
      width?: number;
    };

    const MemoDialog = ({ title, children, width = 600 }: Params) => {
      const isMobile = useMediaQuery("(max-width: 640px)");
      const isFullScreen = isMobileFullScreen ?? isMobile;
      return (
        <Dialog
          open={isOpen}
          onClose={closeDialog}
          fullScreen={isFullScreen}
          fullWidth
          maxWidth={false}
          PaperProps={{
            style: {
              borderRadius: isFullScreen ? 0 : 15,
              minWidth: 280,
              ...(isFullScreen ? {} : { maxWidth: width }),
            },
          }}
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "none",
            },
          }}
          className="z-[99999]"
        >
          {/* Close & Title */}
          <div className="sticky top-0 z-10 flex items-center gap-4 bg-white p-3 font-bold">
            <span
              className="flex h-min w-min cursor-pointer items-center justify-center rounded-full p-2 transition-colors hover:bg-gray-100"
              onClick={closeDialog}
            >
              <CrossIcon />
            </span>
            {title}
          </div>
          {title && <hr />}
          {/* Content */}
          {children}
        </Dialog>
      );
    };
    return MemoDialog;
  }, [closeDialog, isMobileFullScreen, isOpen]);

  return { Dialog: GlobalDialog, isOpen, openDialog, closeDialog };
};
