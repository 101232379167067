"use client";

import Image from "next/image";
import { twMerge } from "tailwind-merge";

import { useLang } from "@/hooks/use-lang";
import iphoneFrame from "@/public/winwingpt/video-frame.png";

const videoPath = {
  zh: {
    desktop: "/winwingpt/ww-desktop-zh.mp4",
    mobile: "/winwingpt/ww-mobile-zh.mp4",
  },
  en: {
    desktop: "/winwingpt/ww-desktop-en.mp4",
    mobile: "/winwingpt/ww-mobile-en.mp4",
  },
};

export const DemoComponent = () => {
  const { lang } = useLang();

  return (
    <div className="relative flex justify-center pt-3">
      <div className="hidden min-h-[504px] w-full max-w-4xl @3xl:block">
        <video
          loop
          muted
          autoPlay
          playsInline
          controls={false}
          controlsList="nofullscreen"
          width="100%"
          height="100%"
          className="overflow-hidden rounded-xl shadow-2xl"
          src={videoPath[lang].desktop}
        />
      </div>
      <div
        className={twMerge(
          "relative flex aspect-[100/205] w-full max-w-xs items-center justify-center @3xl:hidden"
        )}
      >
        <div className="flex items-center justify-center overflow-hidden rounded-[40px]">
          <video
            loop
            muted
            autoPlay
            playsInline
            controls={false}
            controlsList="nofullscreen"
            width="92%"
            height="92%"
            src={videoPath[lang].mobile}
          />
        </div>
        <Image fill alt="video-frame" src={iphoneFrame} className="z-10" />
      </div>
    </div>
  );
};
