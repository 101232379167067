import { animated, useSpring } from "@react-spring/web";
import { useRef } from "react";

export const CircleContainer = ({
  size,
  children,
  triggerAnimation,
}: {
  size: number;
  children?: JSX.Element;
  triggerAnimation: boolean;
}) => {
  const [props, api] = useSpring(() => ({ borderWidth: 0 }));
  const hasTriggeredAnimation = useRef(false);

  if (triggerAnimation && !hasTriggeredAnimation.current) {
    hasTriggeredAnimation.current = true;
    api.start({ borderWidth: 6, delay: 1000 });
  }

  return (
    <animated.div
      style={{ width: size, height: size, borderWidth: props.borderWidth }}
      className="relative flex flex-shrink-0 justify-center rounded-full border-[#1D527E] drop-shadow-[0_3px_6px_rgba(255,255,255,0.25)]"
    >
      {children}
    </animated.div>
  );
};
