"use client";

import Image from "next/image";
import  { useState } from "react";
import { twMerge } from "tailwind-merge";

import { HTMLMarkdown } from "@/components/markdown/HTMLMarkdown";
import { mapNameToOriented } from "@/data/constants/oriented";
import { useDialog } from "@/hooks/use-dialog";
import { useLang } from "@/hooks/use-lang";
import ScoreImage from "@/public/stock-mining/score/score.webp";

import { Button } from "ui/core/button";
import { Select } from "ui/core/select";
import { QuestionIcon } from "ui/icon/shared";
import { ScoreTerms } from "ui/stock-mining/score-terms";
import { ToggleButtons } from "ui/widget/toggle-buttons";

import { getScoreTermsLabel } from "../../data/score";

export const ScoreDetails = () => {
  const { t, lang } = useLang();
  const orientedMap = mapNameToOriented(t);
  const options = Object.keys(orientedMap);
  const [oriented, setOriented] = useState(() => options[0]);
  const { Dialog, openDialog, closeDialog } = useDialog();
  const scoreTermsLabel = getScoreTermsLabel(lang);

  return (
    <>
      <div className="flex items-center gap-4">
        <ToggleButtons
          options={options.map((option) => ({ value: option }))}
          value={oriented}
          onChange={setOriented}
          className={twMerge(
            "my-5 hidden w-[300px] rounded-lg bg-[#F7F7F7] py-1 sm:flex",
            lang === "zh" ? "w-[450px]" : "w-fit"
          )}
          itemClassName={twMerge(
            "text-[#797979] bg-transparent",
            lang === "zh" ? "w-[50px] w-[80px]" : "w-fit px-3"
          )}
          selectedItemClassName="bg-white text-sky-600"
        />
        <Select
          options={options}
          value={oriented}
          onChange={(e) => setOriented(e.target.value)}
          className="my-3 inline-block py-1 sm:hidden"
          aria-label="select score oriented"
        />
        <QuestionIcon
          className="h-6 w-6 cursor-pointer fill-[#2D80AF] transition-colors hover:fill-[#236186]"
          onClick={openDialog}
          role="button"
          aria-label="open score description dialog"
        />
        <Dialog title={t.stockMining.fiveForceDescription} width={800}>
          <div className="flex flex-col items-center">
            <HTMLMarkdown className="p-4 md:px-12 [&>ol]:list-decimal">
              {t.stockMining.scoreDescription}
            </HTMLMarkdown>
            <Button
              className="mx-2 my-4 w-full max-w-[250px]"
              onClick={closeDialog}
              aria-label="close score description dialog"
            >
              {t.stockMining.close}
            </Button>
          </div>
        </Dialog>
      </div>
      <div className="h-full w-full px-12">
        <ScoreTerms
          scoreImage={
            <Image
              src={ScoreImage}
              alt="Score Terms"
              className="h-full w-full"
              priority
            />
          }
          {...scoreTermsLabel[orientedMap[oriented]]}
        />
      </div>
    </>
  );
};
