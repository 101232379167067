"use client";

import { usePathname } from "next/navigation";

import { en, zh } from "@/data/lang";
import { Lang } from "@/i18n-config";

export const useLang = () => {
  const pathname = usePathname() ?? "";
  const lang: Lang = pathname.startsWith("/en") ? "en" : "zh";
  return { t: lang === "en" ? en : zh, lang };
};
