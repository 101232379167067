"use client";

import LinearProgress from "@mui/material/LinearProgress";
import Link from "next/link";
import { useMemo, useState, useTransition } from "react";

import { Oriented, mapNameToOriented } from "@/data/constants/oriented";
import { links } from "@/data/links";
import { useLang } from "@/hooks/use-lang";

import {
  AISectorCarousel,
  AISectorItemProps,
  AISectorTableProps,
} from "ui/stock-mining/ai-sector-carousel";
import { Select } from "ui/widget/select";

import { useAISectorOptional } from "../services/ai-sector-optional";

export const AISectorSection = () => {
  const { t, lang } = useLang();
  const optionMap = useMemo(() => mapNameToOriented(t), [t]);
  const [oriented, setOriented] = useState(Object.keys(optionMap)[0]);
  const [, startTransition] = useTransition();

  const { data, isFetching, isError, isLoading } = useAISectorOptional(
    optionMap[oriented] as Oriented
  );

  return (
    <section className="flex min-h-[680px] flex-col items-center bg-[#EAF2F7] py-9">
      <h2 className="px-7 py-4 text-center text-xl font-bold text-sky-600 md:text-3xl">
        {t.stockMining.easyToUnderstand}
      </h2>
      <p className="px-7 pb-7 text-center text-base">
        {t.stockMining.elevenCategories}
      </p>

      {/* Select */}
      <div className="flex gap-4">
        <div className="flex flex-col gap-2" aria-label="ai sector select">
          <Select
            data={Object.keys(optionMap)}
            value={oriented}
            onValueChange={(option) =>
              startTransition(() => {
                setOriented(option);
              })
            }
            style={{
              width: lang === "zh" ? 140 : 180,
              height: 42,
              background: "white",
            }}
          />
          <LinearProgress
            sx={{
              color: "#297AA9",
              visibility: isFetching ? "visible" : "hidden",
            }}
          />
        </div>
        <div
          className="flex flex-col gap-1"
          aria-label="average score of all market"
        >
          <p className="text-sm text-neutral-500">{`${t.stockMining.overallMarket}${oriented}${t.stockMining.averageScore}`}</p>
          <p className="text-[#202020]">
            {+(data?.allMarketAvgScore.toFixed(2) ?? 0)}
          </p>
        </div>
      </div>

      {/* Table */}
      <div className="flex w-full max-w-[1160px] grow items-center justify-center py-10">
        {isLoading ? (
          <LoadingView />
        ) : isError ? (
          <p className="text-sm text-gray-400">{t.stockMining.someProblem}</p>
        ) : (
          <div className="w-full px-10 xl:px-0">
            <AISectorCarousel
              key={oriented}
              lang={lang}
              data={
                (data?.sector?.map((table) => ({
                  mainCategory: table.mainCategory,
                  avgScore: table.avgScore,
                  oriented,
                  items: table.rank.map((item) => {
                    const { symbol, marketCap, score, companyName } = item;
                    return {
                      symbol,
                      companyName,
                      marketCap,
                      score,
                      itemWrapper: (
                        <Link
                          href={`/${lang}${links.myAnalysisOverview({
                            symbol,
                          })}`}
                          target="_blank"
                        />
                      ),
                    };
                  }) as AISectorItemProps[],
                })) as AISectorTableProps[]) ?? []
              }
            />
          </div>
        )}
      </div>
    </section>
  );
};

const LoadingView = () => (
  <div className="flex gap-6 overflow-hidden px-10">
    {Array.from({ length: 4 }).map((_, index) => (
      <div key={index} className="flex flex-col gap-5">
        <div className="h-5 w-1/2 animate-pulse rounded bg-gray-300" />
        <div className="h-80 w-72 animate-pulse rounded bg-gray-300" />
      </div>
    ))}
  </div>
);
