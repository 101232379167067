import { twMerge } from "tailwind-merge";

import { DialogCardProps } from "./DialogCardProps";

const shadowPointerStyleMap = new Map([
  ["top", "left-[50%] top-[-30px] translate-x-[-50%] border-b-[#fff]"],
  ["bottom", "left-[50%] bottom-[-30px] translate-x-[-50%] border-t-[#fff]"],
  ["right", "right-[-30px] top-[50%] translate-y-[-50%] border-l-[#fff]"],
  ["left", "left-[-30px] top-[50%] translate-y-[-50%] border-r-[#fff]"],
]);

const borderPointerStyleMap = new Map([
  [
    "top",
    "top-[-9px] left-[50%] translate-x-[-50%] border-l-[#D9D9D9] border-t-[#D9D9D9]",
  ],
  [
    "bottom",
    "bottom-[-9px] left-[50%] translate-x-[-50%] border-r-[#D9D9D9] border-b-[#D9D9D9]",
  ],
  [
    "right",
    "right-[-9px] top-[50%] translate-y-[-50%] border-r-[#D9D9D9] border-t-[#D9D9D9]",
  ],
  [
    "left",
    "left-[-9px] top-[50%] translate-y-[-50%] border-l-[#D9D9D9] border-b-[#D9D9D9]",
  ],
]);

const DialogCard = ({
  showShadow = false,
  anchor = "none",
  content,
  className,
  style,
}: DialogCardProps) => {
  const cardStyle = showShadow
    ? "drop-shadow-[0px_6px_20px_rgba(0,0,0,0.16)]"
    : "border-solid border-[1px] border-[#D9D9D9]";
  const pointerStyle =
    anchor === "none"
      ? ""
      : showShadow
      ? `border-solid border-transparent border-[15px] ${
          shadowPointerStyleMap.get(anchor) ?? ""
        }`
      : `w-[15px] h-[15px] bg-white border-solid border-transparent border-[1px] rotate-[45deg] ${
          borderPointerStyleMap.get(anchor) ?? ""
        }`;

  return (
    <div
      className={twMerge(
        `relative rounded-lg bg-white p-3 ${cardStyle}`,
        className
      )}
      aria-label="dialog-card"
      style={style}
    >
      {anchor !== "none" && <div className={`absolute ${pointerStyle}`} />}
      {content}
    </div>
  );
};

export default DialogCard;
