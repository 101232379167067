import React from "react";

import { AISectorItemProps } from "./AISectorCarouselProps";

const borderColors = [
  "border-[#ECB014]",
  "border-[#FFCF50]",
  "border-[#E4EB8B]",
  "border-[#80D6D3]",
  "border-[#14B3BC]",
];

export const AISectorItem = ({
  symbol,
  companyName,
  score,
  marketCap,
  itemWrapper,
}: AISectorItemProps) => {
  return (
    <div className="flex h-[54px] w-full max-w-[300px] items-center justify-between p-2">
      <div className="flex w-full flex-grow flex-col items-start truncate pr-6">
        {itemWrapper ? (
          React.cloneElement(itemWrapper as React.ReactElement, {
            children: (
              <p className="cursor-pointer font-medium text-sky-700 transition hover:text-sky-600">
                {symbol}
              </p>
            ),
          })
        ) : (
          <p className="cursor-pointer font-medium text-sky-700 transition hover:text-sky-600">
            {symbol}
          </p>
        )}
        <p className="w-full truncate text-start text-sm">{companyName}</p>
      </div>
      <div className="flex min-w-[110px] items-center justify-between gap-4 pr-3">
        <div
          className={`flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white ${
            borderColors[score - 1]
          }`}
        >
          {score}
        </div>
        <p className="text-sm">
          {Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(marketCap)}
        </p>
      </div>
    </div>
  );
};
