"use client";

import { useWindowSize } from "hooks/use-window-size";
import Image, { StaticImageData } from "next/image";
import Link from "next/link";
import { useMemo, useState } from "react";

import {
  Oriented,
  mapNameToOriented,
  mapOrientedToName,
} from "@/data/constants/oriented";
import { links } from "@/data/links";
import { useLang } from "@/hooks/use-lang";
import koIcon from "@/public/stock-mining/example/icon/ko.png";
import tslaIcon from "@/public/stock-mining/example/icon/tsla.png";
import flagIcon from "@/public/stock-mining/example/icon/us.png";
import xomIcon from "@/public/stock-mining/example/icon/xom.png";

import { Button } from "ui/core/button";
import { HoverRadarChart } from "ui/stock-mining/hover-radar-chart";
import { DialogCard } from "ui/widget/dialog-card";
import { ToggleButtons } from "ui/widget/toggle-buttons";

import {
  CompanyData,
  CompanyInfo,
  ImageData,
  OrientedDescription,
} from "../data/example";

export const ExampleSection = () => {
  const { t, lang } = useLang();

  const labels = ["Tesla", "Coca-Cola", "Exxon Mobil"];
  const [label, setLabel] = useState(labels[0]);

  const companyData = CompanyData[labels.indexOf(label)];
  const [oriented, setOriented] = useState<Oriented>(companyData.focus);

  const orientedMap = useMemo(() => mapNameToOriented(t), [t]);
  const nameMap = useMemo(() => mapOrientedToName({ t }), [t]);

  const onCompanyChange = (label: string) => {
    setLabel(label);
    setOriented(CompanyData[labels.indexOf(label)].focus);
  };

  const onOrientedChange = (value: string) => {
    setOriented(orientedMap[value] as Oriented);
  };

  return (
    <section className="h-full min-h-[680px] w-full bg-[#EAF2F7] py-9">
      <div className="flex w-full flex-col items-center gap-5">
        {/* Title */}
        <h2 className="text-xl font-bold text-sky-600 md:text-3xl">
          {t.stockMining.useCases}
        </h2>

        {/* Select */}
        <div className="w-full max-w-md">
          <ToggleButtons
            options={labels.map((label) => ({ value: label }))}
            value={label}
            onChange={onCompanyChange}
            className="flex w-full items-center gap-1 sm:gap-5"
            itemClassName="bg-[#F9F9F9] w-full max-w-32 rounded-full md:text-base font-medium"
            selectedItemClassName="text-white bg-[#58C1D7]"
          />
        </div>

        <div className="flex max-w-[900px] flex-col items-center gap-4 md:py-4 lg:flex-row">
          {/* Info & RadarChart */}
          <InfoRadarChart
            companyData={companyData}
            labels={Object.keys(orientedMap)}
            onChange={onOrientedChange}
            focus={nameMap[oriented]}
          />
          {/* Preview Card */}
          <PreviewCard
            companyData={companyData}
            orientedName={nameMap[oriented]}
            oriented={oriented}
          />
        </div>

        {/* Button */}
        <Link
          href={`/${lang}${links.myAnalysisOverview({
            symbol: companyData.symbol.toUpperCase(),
          })}`}
          target="_blank"
          className="w-full max-w-xs px-3"
          aria-label={`go to ${label} analysis page`}
        >
          <Button
            variant="primary"
            className="h-11 w-full capitalize md:text-base"
          >
            {t.stockMining.startForFree}
          </Button>
        </Link>
      </div>
    </section>
  );
};

const companyIcons = {
  tsla: tslaIcon,
  ko: koIcon,
  xom: xomIcon,
} as const;

const InfoRadarChart = ({
  companyData,
  labels,
  focus,
  onChange,
}: {
  companyData: CompanyInfo;
  labels: string[];
  onChange: (label: string) => void;
  focus: string;
}) => {
  const companyIcon =
    companyIcons[companyData.symbol as keyof typeof companyIcons];
  const { lang } = useLang();
  const symbol = companyData.symbol.toUpperCase();
  const companyName = `${companyData.name}, Inc.`;
  const { value, trend, swing, chip, dividend } = companyData.score;

  return (
    <div
      className="flex flex-col items-center gap-3"
      aria-label="info radar chart"
    >
      {/* Info */}
      <div className="flex items-center gap-2">
        <Image src={companyIcon} alt={symbol} width={20} height={20} priority />
        <p className="text-lg font-bold">{companyName}</p>
        <p className="text-sm">{symbol.toUpperCase()}</p>
        <Image src={flagIcon} alt="Flag" width={16} height={16} priority />
        <p className="text-sm">NASDAQ</p>
      </div>

      {/* Chart */}
      <div className="my-4 h-[230px] w-[315px] md:h-[270px] md:w-[360px]">
        <HoverRadarChart
          data={{
            [labels[0]]: value,
            [labels[1]]: trend,
            [labels[2]]: swing,
            [labels[3]]: chip,
            [labels[4]]: dividend,
          }}
          focusValue={focus}
          onChange={onChange}
          lang={lang}
        />
      </div>
    </div>
  );
};

const PreviewCard = ({
  companyData,
  oriented,
  orientedName,
}: {
  companyData: CompanyInfo;
  oriented: Oriented;
  orientedName: string;
}) => {
  const { width } = useWindowSize();
  const { lang, t } = useLang();
  const imageData: StaticImageData =
    ImageData[companyData.symbol as "ko" | "tsla" | "xom"][lang][oriented];

  return (
    <DialogCard
      anchor={width && width < 1024 ? "top" : "left"}
      content={
        <div
          className="flex h-[340px] w-full max-w-[420px] flex-col items-center gap-5 px-0 md:px-8 md:py-2"
          aria-label="preview card"
        >
          <div className="flex w-full items-center justify-between">
            <p className="text-sm font-bold text-sky-600">
              {`${orientedName}：${companyData.score[oriented]}${
                lang === "zh" ? "分" : ""
              }`}
            </p>
            <p className="text-xs text-[#939393]">{`${t.stockMining.updatedTo} ${companyData.updatedAt}`}</p>
          </div>
          <p className="text-sm">{OrientedDescription[lang][oriented]}</p>
          <div className="relative flex h-full w-full items-center justify-center">
            <Image
              src={imageData}
              alt={`${companyData.name}'s ${orientedName}`}
              className="h-min w-full object-contain"
            />
          </div>
        </div>
      }
      showShadow
      style={{ padding: 20, margin: "0px 30px 10px 30px" }}
    />
  );
};
