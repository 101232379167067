"use client";

import { useState } from "react";
import { twMerge } from "tailwind-merge";

import { ArrowDownIcon } from "@/icon/shared";

import { MemoAccordionItem } from "./AccordionItem";
import { AccordionProps } from "./AccordionProps";

export default function Accordion({
  data,
  mustShown = false,
  defaultActiveIndex = null,
  showTopLine = false,
  showBottomLine = false,
  showActiveLine = false,
  activeLineColor = "linear-gradient(90deg, rgba(88,193,215,1) 0%, rgba(45,128,175,1) 100%)",
  iconAnchor = "left",
  icon = <ArrowDownIcon />,
  onChange,
  containerClassName,
  itemClassName,
}: AccordionProps) {
  const topLineStyle =
    showTopLine && !showActiveLine ? "border-t-[1px] border-t-stone-300" : "";
  const BottomLineStyle = showBottomLine
    ? "border-b-[1px] border-b-stone-300"
    : "";
  const [active, setActive] = useState<number | null>(() =>
    mustShown ? 0 : defaultActiveIndex
  );
  const handleOnClick = (index: number) => {
    const activeIndex = index === active ? (mustShown ? index : null) : index;
    setActive(activeIndex);
    onChange?.(activeIndex);
  };

  return (
    <div className={twMerge(topLineStyle, BottomLineStyle, containerClassName)}>
      {data.map((data, index) => (
        <MemoAccordionItem
          key={index}
          expanded={active === index}
          onClick={handleOnClick.bind(null, index)}
          icon={icon}
          iconAnchor={iconAnchor}
          title={data.title}
          content={data.content}
          showActiveLine={showActiveLine}
          activeLineColor={activeLineColor}
          className={itemClassName}
          childrenLabel={`accordion item ${index}`}
        />
      ))}
    </div>
  );
}
