import { animated, useSpring } from "@react-spring/web";
import { useMemo, useRef } from "react";
import { twMerge } from "tailwind-merge";

export const CircleButtons = ({
  placeHolders,
  items,
  selected,
  isMobileSize,
  onClick,
  triggerAnimation,
  lang,
}: {
  placeHolders: string[];
  items: string[];
  isMobileSize: boolean;
  selected: number;
  onClick: (index: number) => void;
  triggerAnimation: boolean;
  lang: string;
}) => {
  const hasTriggeredAnimation = useRef(false);

  const mobileStyles = useMemo(
    () => [
      { top: "-16%" },
      { top: "23%", right: "-18%" },
      { bottom: "-13%", right: "1%" },
      { bottom: "-13%", left: "1%" },
      { top: "23%", left: "-18%" },
    ],
    []
  );

  const [textProps, textApi] = useSpring(() => ({
    opacity: 0,
  }));

  const [props, api] = useSpring(() => ({
    first_top: "0%",
    second_top: "20%",
    second_right: "75%",
    third_bottom: "25%",
    third_right: "40%",
    fourth_bottom: "-5%",
    fourth_left: "0%",
    fifth_top: "60%",
    fifth_left: "65%",
    buttonBorderWidth: 10,
    buttonFontSize: 24,
    buttonSize: 150,
  }));

  if (triggerAnimation && !hasTriggeredAnimation.current) {
    hasTriggeredAnimation.current = true;
    textApi.start({ opacity: 1 });
    api.start({
      first_top: "-12%",
      second_top: "30%",
      second_right: "-11%",
      third_bottom: "-3%",
      third_right: "10%",
      fourth_bottom: "-3%",
      fourth_left: "10%",
      fifth_top: "30%",
      fifth_left: "-11%",
      buttonBorderWidth: 6,
      buttonFontSize: 20,
      buttonSize: 110,
      config: {
        mass: 1,
        tension: 200,
        friction: 70,
        precision: 0.00001,
        velocity: 0,
        clamp: true,
      },
    });
  }

  const styles = [
    { top: props.first_top },
    { top: props.second_top, right: props.second_right },
    { bottom: props.third_bottom, right: props.third_right },
    { bottom: props.fourth_bottom, left: props.fourth_left },
    { top: props.fifth_top, left: props.fifth_left },
  ];

  return (
    <>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <animated.button
            className={twMerge(
              "absolute flex cursor-pointer items-center justify-center rounded-full font-bold transition-all hover:border-[#58C1D7] hover:bg-white hover:text-[#135786]",
              hasTriggeredAnimation.current
                ? selected === index
                  ? "border-[#58C1D7] bg-white text-[#135786]"
                  : "border-[#1D527E] bg-[#2574A4] text-[rgba(255,255,255,0.6)]"
                : "border-[#58C1D7] bg-[#135786] text-white",
              lang === "en" && "leading-tight"
            )}
            onClick={onClick.bind(null, index)}
            key={index}
            style={{
              fontSize: isMobileSize ? 16 : props.buttonFontSize,
              borderWidth: isMobileSize ? 4 : props.buttonBorderWidth,
              width: isMobileSize ? 80 : props.buttonSize,
              height: isMobileSize ? 80 : props.buttonSize,
              ...(isMobileSize ? mobileStyles[index] : styles[index]),
            }}
          >
            <animated.div
              className="absolute"
              style={{
                opacity: isMobileSize
                  ? 0
                  : textProps.opacity.to((op) => 1 - op),
              }}
            >
              {placeHolders[index].split(" ").map((text) => (
                <div className="whitespace-nowrap" key={text}>
                  {text}
                </div>
              ))}
            </animated.div>
            <animated.p
              className="absolute"
              style={{ opacity: textProps.opacity }}
              aria-label={`select ${items[index]}`}
            >
              {items[index]}
            </animated.p>
          </animated.button>
        ))}
    </>
  );
};
