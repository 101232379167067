import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { twMerge } from "tailwind-merge";

import { ToggleButtonsProps } from "./ToggleButtonsProps";

function ToggleButtons({
  options,
  value: selectedValue,
  onChange,
  className,
  itemClassName = "",
  selectedItemClassName = "",
  fullWidth = true,
  ...props
}: ToggleButtonsProps) {
  const handleAlignment = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment == null) return;
    onChange(newAlignment);
  };

  return (
    <ToggleButtonGroup
      {...props}
      fullWidth={fullWidth}
      value={selectedValue}
      exclusive
      onChange={handleAlignment}
      className={twMerge("bg-transparent", className)}
      data-testid="toggle-buttons"
    >
      {options.map((option, index) => {
        const isSelected = option.value === selectedValue;
        const style = isSelected
          ? "text-data-primary shadow-xl shadow-[2px_2px_5px_0px_rgba(0,0,0,0.1)]"
          : "text-neutral-500";

        return (
          <ToggleButton
            key={index}
            value={option.value}
            disableTouchRipple={true}
            className="group border-0 px-1 py-0 normal-case hover:bg-transparent [&.Mui-selected]:bg-transparent"
            disabled={option.isDisabled}
          >
            <p
              className={twMerge(
                `truncate rounded-lg bg-white px-[8px] py-[7px] text-sm group-disabled:opacity-50 ${style}`,
                itemClassName,
                // className from option can override default style
                option.className,
                isSelected && selectedItemClassName,
                // selectedClassName from option can override default style
                isSelected && option.selectedClassName
              )}
            >
              {option.value}
            </p>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export default ToggleButtons;
