import Markdown from "markdown-to-jsx";
import { twMerge } from "tailwind-merge";

import styles from "./HTMLMarkdown.module.scss";

export const HTMLMarkdown = ({
  children,
  className,
}: {
  children: string;
  className?: string;
}) => (
  <Markdown
    className={twMerge(className, styles.markdown)}
    options={{
      overrides: {
        a: ({ href, children }) => (
          <a href={href as string} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ),
      },
    }}
  >
    {children}
  </Markdown>
);
