import backtestImageEN from "@/public/stock-mining/feature/backtest-en.jpg";
import backtestResultImageEN from "@/public/stock-mining/feature/backtest-result-en.jpg";
import backtestResultImageZH from "@/public/stock-mining/feature/backtest-result-zh.jpg";
import backtestImageZH from "@/public/stock-mining/feature/backtest-zh.jpg";
import growinIndicatorImageEN from "@/public/stock-mining/feature/growin-indicator-en.jpg";
import growinIndicatorImageZH from "@/public/stock-mining/feature/growin-indicator-zh.jpg";
import historicalRatingImageEN from "@/public/stock-mining/feature/historical-rating-en.jpg";
import historicalRatingImageZH from "@/public/stock-mining/feature/historical-rating-zh.jpg";
import screenerImageEN from "@/public/stock-mining/feature/screener-en.jpg";
import screenerImageZH from "@/public/stock-mining/feature/screener-zh.jpg";

export const Images = {
  zh: [
    growinIndicatorImageZH,
    historicalRatingImageZH,
    backtestImageZH,
    backtestResultImageZH,
    screenerImageZH,
  ],
  en: [
    growinIndicatorImageEN,
    historicalRatingImageEN,
    backtestImageEN,
    backtestResultImageEN,
    screenerImageEN,
  ],
};

export const Alts = [
  "Growin Indicator",
  "Historical Rating",
  "Backtest",
  "Backtest Result",
  "Screener",
];

export const Titles = {
  zh: [
    "Growin 獨家指標",
    "歷史分數",
    "進、出場回測模擬",
    "回測結果與類股、整體市場比較",
    "篩選器",
  ],
  en: [
    "Growin Proprietary Indicator",
    "AI Historical Score",
    "Backtesting Simulation",
    "Stocks & Market Comparison",
    "Screener",
  ],
};

export const Descriptions = {
  zh: [
    "可透過 Power Squeeze 指標觀察標的的能量累積狀態，再搭配 Surfing Trend 指標衡量其趨勢走向",
    "投資人可透過 AI 歷史評分在 3 分以上時對應股價的走勢，觀察是否會有比較突出的表現，以此來輔助投資人作投資決策",
    "依指定的進、出場規則進行回溯測試，最早自 2007 年至今，可觀察進、出場時間點、累積報酬率及勝率統計",
    "提供投資人回測結果與類股、整體市場、標普 500 進行比較，可了解標的於大環境的表現狀況",
    "透過五力評分、產業類別、市值、交易量等資訊，收斂標的搜尋範圍，找出您不知道但也有一樣潛力的股票標的",
  ],
  en: [
    "The Power Squeeze and Surfing Trend indicators help identify stocks with accumulated energy and capture signals for significant upward movements.",
    "AI historical scores give investors insights on stock performance relative to the AI score, with scores of 3 or higher indicating more prominent performances.decisions.",
    "Create AI-based entry and exit rules, backtest and analyze performance from 2007, and identify optimal strategies for your preferred stocks.",
    "Compare your favorite stocks' performance with similar sectors, the overall market, and the S&P 500 benchmark for confident decision-making.",
    "Discover potential stocks with ease using Growin's 5-dimensional AI scoring and proprietary indicators.",
  ],
};
