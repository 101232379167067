"use client";

import { useMemo } from "react";

import { useLang } from "@/hooks/use-lang";

import { InvestorCircleCarousel } from "ui/stock-mining/investor-circle-carousel";

import {
  getDescriptions,
  getItems,
  getPlaceHolders,
  getTags,
  getTitles,
} from "../../data/style";

const ClientInvestorCircleCarousel = () => {
  const { lang } = useLang();

  const placeHolders = useMemo(() => getPlaceHolders(lang), [lang]);
  const titles = useMemo(() => getTitles(lang), [lang]);
  const descriptions = useMemo(() => getDescriptions(lang), [lang]);
  const tags = useMemo(() => getTags(lang), [lang]);
  const items = useMemo(() => getItems(lang), [lang]);

  return (
    <div className="h-full max-h-[600px] w-full max-w-[600px] px-7">
      <InvestorCircleCarousel
        placeHolders={placeHolders}
        titles={titles}
        descriptions={descriptions}
        tags={tags}
        items={items}
        lang={lang}
      />
    </div>
  );
};

export default ClientInvestorCircleCarousel;
