import { Country } from "@/data/constants/country";
import { WarningIndicatorPathname } from "@/features/market-analysis/warning-indicator/indicator/data/indicator-links";
import { Category } from "@/features/media-center/services/get-category-data";

const authLinks = {
  authLogin: "/auth/login",
  authLoginBusiness: "/auth/login-business",
  authSignup: "/auth/signup",
  authBusinessSupport: "/auth/business-support",
  authForgotPassword: "/auth/forgot-password",
  authEmailVerification: "/auth/email-verification",
  authResetPassword: "/auth/reset-password",
  authLineLogin: "/auth/line",
};

const helpLinks = {
  help: "/help",
  helpDetail: ({ id }: { id: string }) => `/help/${id}`,
};

const my13FLinks = {
  my13F: "/my/13f",
  my13FDetail: ({ investor }: { investor: string }) => `/my/13f/${investor}`,
};

const myAnalysisLinks = {
  myAnalysisSearch: "/my/analysis",
  myAnalysisOverview: ({ symbol }: { symbol: string }) =>
    `/my/analysis/${symbol}`,
  myAnalysisValue: ({ symbol }: { symbol: string }) =>
    `/my/analysis/${symbol}/value`,
  myAnalysisTrend: ({ symbol }: { symbol: string }) =>
    `/my/analysis/${symbol}/trend`,
  myAnalysisSwingTrading: ({ symbol }: { symbol: string }) =>
    `/my/analysis/${symbol}/swing-trading`,
  myAnalysisWhaleInterest: ({ symbol }: { symbol: string }) =>
    `/my/analysis/${symbol}/whale-interest`,
  myAnalysisDividend: ({ symbol }: { symbol: string }) =>
    `/my/analysis/${symbol}/dividend`,
  myAnalysisTradingViewAdvancedChart: ({ symbol }: { symbol: string }) =>
    `/my/analysis/${symbol}/tradingview-advanced-chart`,
};

const myMarketAnalysisLinks = {
  myMarketAnalysisFearAndGreed: "/my/market-analysis/fear-and-greed",
  myMarketAnalysisEarlyWarningIndicatorHistoricalSignal:
    "/my/market-analysis/market-early-warning-indicator/historical-signal",
  myMarketAnalysisEarlyWarningIndicatorFundingLevelBacktest:
    "/my/market-analysis/market-early-warning-indicator/funding-level-backtest",
  myMarketAnalysisEarlyWarningIndicatorIndicator: ({
    indicator,
  }: {
    indicator: WarningIndicatorPathname;
  }) =>
    `/my/market-analysis/market-early-warning-indicator/indicator/${indicator}`,
};

const myNotificationLinks = {
  myNotification: "/my/notification",
  myNotificationLogs: "/my/notification/logs",
  myNotificationSettings: "/my/notification/settings",
};

const myAccountLinks = {
  myAccount: "/my/account",
};

const myMediaCenterLinks = {
  myMediaCategory: ({ category }: { category: Category }) =>
    `/my/media-center/${category}`,
  myMedia: ({ category, slug }: { category: Category; slug: string }) =>
    `/my/media-center/${category}/${slug}`,
};

const myWinwingptLinks = {
  winwingptWaitlist: "/winwingpt-waitlist",
  myWinwingpt: "/my/winwingpt",
  myWinwingptQuestion: ({ id }: { id: string }) => `/my/winwingpt/${id}`,
};

const myBlogLinks = {
  myBlog: "/my/blog",
  myBlogDetail: ({ slug }: { slug: string }) => `/my/blog/${slug}`,
};

export const links = {
  ...authLinks,
  ...helpLinks,
  ...my13FLinks,
  ...myAnalysisLinks,
  ...myMarketAnalysisLinks,
  ...myNotificationLinks,
  ...myAccountLinks,
  ...myMediaCenterLinks,
  ...myWinwingptLinks,
  ...myBlogLinks,
  home: "/",
  about: "/about",
  changelog: "/changelog",
  contact: "/contact",
  roboAdvisor: "/robo-advisor",
  stockMiningPricing: "/stockmining-pricing",
  stockMining: "/stockmining",
  technology: "/technology",
  paymentSuccess: "/payment-success",
  lineBinding: "/line-binding",
  myScreener: ({ country }: { country: Country }) => `/my/screener/${country}`,
  myRanking: ({ country, category }: { country: Country; category: string }) =>
    `/my/ranking/${country}/${category}`,
  event: ({ name }: { name: string }) => `/${name}`,
  service: ({
    name,
    query,
  }: {
    name: string;
    query?: { [key: string]: string };
  }) =>
    `/service/${name}${
      query
        ? `?${Object.entries(query)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : ""
    }`,
};
