import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { Oriented } from "@/data/constants/oriented";
import { NIKE_URL } from "@/data/constants/url";
import { useLang } from "@/hooks/use-lang";
import { Lang } from "@/i18n-config";

type AISectorOptionalParams = {
  oriented: Oriented;
  lang: Lang;
};

const symbolInfoSchema = z.object({
  symbol: z.string(),
  companyName: z.string(),
  marketCap: z.number(),
  score: z.number(),
});

const sectorSchema = z.object({
  mainCategory: z.string(),
  avgScore: z.number(),
  rank: z.array(symbolInfoSchema),
});

const aiSectorResultSchema = z.object({
  data: z.object({
    allMarketAvgScore: z.number(),
    sector: z.array(sectorSchema),
  }),
  message: z.string(),
});

export type AISectorResult = z.infer<typeof aiSectorResultSchema>["data"];

export const getAISectorOptional = async ({
  oriented,
  lang,
}: AISectorOptionalParams): Promise<AISectorResult> => {
  const endpoint = `/symbol-industry-ranking/${oriented}`;
  const res = await fetch(`${NIKE_URL}${endpoint}`, {
    method: "GET",
    headers: { "Accept-Language": lang },
  });

  if (!res.ok) throw new Error(`API failed (${endpoint})`);
  return aiSectorResultSchema.parse(await res.json()).data;
};

export const useAISectorOptional = (oriented: Oriented) => {
  const { lang } = useLang();
  return useQuery(
    ["ai-sector-optional", oriented, lang],
    () => getAISectorOptional({ oriented, lang }),
    { keepPreviousData: true, enabled: !!oriented }
  );
};
