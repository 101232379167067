"use client";

import { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { twMerge } from "tailwind-merge";

import { CircleButtons } from "./CircleButtons";
import { CircleContainer } from "./CircleContainer";
import { Content } from "./Content";
import { InvestorCircleCarouselProps } from "./InvestorCircleCarouselProps";

export const InvestorCircleCarousel = ({
  placeHolders,
  items,
  titles,
  descriptions,
  tags,
  lang = "zh",
}: InvestorCircleCarouselProps) => {
  const [ref, { width, height }] = useMeasure();
  const length = Math.min(width, height);
  const isMobileSize = length < 490;
  const [selected, setSelected] = useState<number | null>(null);

  useEffect(() => {
    if (selected != null) return;
    setSelected(width > 0 && isMobileSize ? 0 : null);
  }, [selected, width, isMobileSize]);

  const containerLayout = isMobileSize
    ? "flex flex-col items-center pt-8 gap-10"
    : "flex items-center justify-center";

  const circleSize = isMobileSize ? 210 : length - 100;

  return (
    <div className="flex h-full w-full items-center justify-center" ref={ref}>
      <div
        style={{ width: length, height: isMobileSize ? "100%" : length }}
        className={twMerge("relative", containerLayout)}
        data-testid="container"
      >
        <CircleContainer size={circleSize} triggerAnimation={selected != null}>
          <CircleButtons
            placeHolders={placeHolders}
            items={items}
            isMobileSize={isMobileSize}
            selected={selected ?? -1}
            onClick={setSelected}
            triggerAnimation={selected != null}
            lang={lang}
          />
        </CircleContainer>
        <Content
          title={selected != null ? titles[selected] : ""}
          description={selected != null ? descriptions[selected] : ""}
          tags={selected != null ? tags[selected] : []}
          isMobile={isMobileSize}
          maxWidth={isMobileSize ? length - 24 : length - 260}
          lang={lang}
        />
      </div>
    </div>
  );
};
