import useMeasure from "react-use-measure";
import { twMerge } from "tailwind-merge";

import { ConditionWrapper } from "@/utils/ConditionWrapper";
import { DialogCard } from "@/widget/dialog-card";

import { ScoreTermsProps } from "./ScoreTermsProps";

export const ScoreTerms = ({
  scoreImage,
  leftTitle,
  rightTitle,
  leftIndicator,
  rightIndicator,
  leftItems,
  rightItems,
}: ScoreTermsProps) => {
  const [ref, { width }] = useMeasure();
  const isLargeSize = width >= 900;
  const isMediumSize = width >= 500;

  const card1Style = isLargeSize
    ? { flexBasis: 265, minHeight: 135, order: 1 }
    : { order: 2, width: isMediumSize ? "260px" : "100%" };
  const card1Anchor = isLargeSize ? "right" : "none";

  const card2Style = isLargeSize
    ? { flexBasis: 265, minHeight: 135, order: 3 }
    : { order: 3, width: isMediumSize ? "260px" : "100%" };
  const card2Anchor = isLargeSize ? "left" : "none";

  const containerFlex = isLargeSize ? "flex" : "flex flex-col";
  const indicatorFontSize = isLargeSize ? "text-xl" : "text-base";

  return (
    <div
      ref={ref}
      className={`${containerFlex} max-w-[1050px] items-center justify-center gap-8`}
      aria-label="score terms"
    >
      <Center
        scoreImage={scoreImage}
        leftIndicator={leftIndicator}
        rightIndicator={rightIndicator}
        indicatorFontSize={indicatorFontSize}
      />
      <ConditionWrapper
        wrapper={(children) => (
          <div
            className="order-2 flex w-full items-center justify-between"
            data-testid="wrapper"
          >
            {children}
          </div>
        )}
        showWrapper={!isLargeSize && isMediumSize}
      >
        <>
          <DialogCard
            anchor={card1Anchor}
            content={
              <DialogContent
                title={leftTitle}
                items={leftItems}
                titleColor="#EEBA31"
              />
            }
            style={card1Style}
          />
          <DialogCard
            anchor={card2Anchor}
            content={
              <DialogContent
                title={rightTitle}
                items={rightItems}
                titleColor="#14B3BC"
              />
            }
            style={card2Style}
          />
        </>
      </ConditionWrapper>
    </div>
  );
};

const DialogContent = ({
  title,
  items,
  titleColor,
}: {
  title: string;
  titleColor: string;
  items: string[];
}) => {
  return (
    <div className="flex flex-col gap-3 px-2 py-1">
      <p className="font-bold" style={{ color: titleColor }}>
        {title}
      </p>
      <ul className="list-none text-sm leading-5">
        {items.map((item, index) => (
          <li key={index} className="flex items-center gap-3">
            <span className="h-1 w-1 rounded-full bg-black" />
            <p>{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Center = ({
  leftIndicator,
  rightIndicator,
  indicatorFontSize,
  scoreImage,
}: {
  leftIndicator: string[];
  rightIndicator: string[];
  indicatorFontSize: string;
  scoreImage: JSX.Element;
}) => (
  <div className="relative order-2 flex w-full max-w-[450px] flex-col gap-6">
    {scoreImage}
    <div className="flex justify-between">
      <div
        className={twMerge(
          "flex h-8 flex-col text-center font-bold",
          indicatorFontSize
        )}
        aria-label="left-indicator"
      >
        {leftIndicator.map((text) => (
          <p className="whitespace-nowrap text-xl" key={text}>
            {text}
          </p>
        ))}
      </div>
      <div
        className={twMerge(
          "flex h-8 flex-col text-center font-bold",
          indicatorFontSize
        )}
        aria-label="right-indicator"
      >
        {rightIndicator.map((text) => (
          <p className="whitespace-nowrap text-xl" key={text}>
            {text}
          </p>
        ))}
      </div>
    </div>
  </div>
);
