"use client";

import Image, { StaticImageData } from "next/image";
import { useState } from "react";

import { useLang } from "@/hooks/use-lang";

import { ArrowDownIcon } from "ui/icon/shared";
import { Accordion } from "ui/widget/accordion";
import { DialogCard } from "ui/widget/dialog-card";

import { Alts, Descriptions, Images, Titles } from "../data/feature";

export const FeatureSection = () => {
  const { t, lang } = useLang();
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <section className="flex w-full flex-col items-center justify-center gap-10 py-9 md:py-16">
      <h2 className="text-xl font-bold text-sky-600 md:text-3xl">
        {t.stockMining.highlight}
      </h2>
      <div className="flex w-full max-w-[1160px] items-center justify-around">
        <AccordionComponent setSelectedIndex={setSelectedIndex} />
        <DialogCard
          anchor="none"
          className="hidden max-w-[590px] overflow-hidden p-4 lg:block"
          content={
            <Image
              src={Images[lang][selectedIndex]}
              alt={Alts[selectedIndex]}
              placeholder="blur"
              className="h-full w-full object-contain"
              aria-label={`feature image ${Alts[selectedIndex]}`}
            />
          }
          showShadow
        />
      </div>
    </section>
  );
};

const AccordionComponent = ({
  setSelectedIndex,
}: {
  setSelectedIndex: (index: number) => void;
}) => {
  const { lang } = useLang();

  return (
    <div className="max-w-[590px] px-8 lg:max-w-[485px]">
      <Accordion
        showBottomLine
        activeLineColor="linear-gradient(90deg, rgba(88,193,215,1) 0%, rgba(45,128,175,1) 100%)"
        data={Array(5)
          .fill(0)
          .map((_, i) => ({
            title: <Title>{Titles[lang][i]}</Title>,
            content: (
              <Content imageUrl={Images[lang][i]} alt={Alts[i]}>
                {Descriptions[lang][i]}
              </Content>
            ),
          }))}
        defaultActiveIndex={null}
        icon={<ArrowDownIcon />}
        iconAnchor="right"
        mustShown
        onChange={(index) => setSelectedIndex(index ?? 0)}
        showActiveLine
      />
    </div>
  );
};

const Title = ({ children }: { children: string }) => (
  <p className="font-bold">{children}</p>
);

const Content = ({
  children,
  imageUrl,
  alt,
}: {
  children: string;
  imageUrl: StaticImageData;
  alt: string;
}) => (
  <div className="flex w-full flex-col items-center gap-5">
    <p className="text-sm">{children}</p>
    <DialogCard
      anchor="none"
      className="block scale-90 p-4 lg:hidden"
      content={
        <Image
          src={imageUrl}
          alt={alt}
          placeholder="blur"
          className="h-full w-full object-contain"
          aria-label={`feature image ${alt} mobile`}
        />
      }
      showShadow
    />
  </div>
);
