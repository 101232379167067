import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

export const LoadingIndicator = (props: ComponentProps<"div">) => (
  <div {...props} className={twMerge("flex space-x-1", props.className)}>
    <div className="h-2 w-2 animate-[pulse_1s_infinite] rounded-full bg-current" />
    <div className="h-2 w-2 animate-[pulse_1s_infinite_100ms] rounded-full bg-current" />
    <div className="h-2 w-2 animate-[pulse_1s_infinite_200ms] rounded-full bg-current" />
  </div>
);
