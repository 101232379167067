import { twMerge } from "tailwind-merge";

type Props = {
  title: string;
  description: string;
  tags: string[];
  lang: string;
  isMobile: boolean;
  maxWidth: number;
};

export const Content = ({
  title,
  description,
  tags,
  lang,
  isMobile,
  maxWidth,
}: Props) => (
  <div
    className={twMerge(
      "absolute -z-10 flex flex-col items-center justify-center gap-6 pb-8 opacity-0 transition-opacity delay-1000 duration-1000",
      title && description && tags.length !== 0 && "z-0 opacity-100",
      isMobile && "relative"
    )}
    style={{ maxWidth }}
    aria-label={title}
  >
    <p className="text-xl font-medium text-white">{title}</p>
    <p className="text-center text-sm text-white">{description}</p>
    <p className="text-center text-sm text-white">
      {lang === "zh"
        ? "推薦搭配使用的五力分數："
        : `AI Score Fusion for ${title.split(" ")[0]} Investing:`}
    </p>
    <div className="flex flex-wrap justify-center gap-2 text-sm font-medium text-[#135786]">
      {tags.map((tag) => (
        <span
          key={tag}
          className="select-none whitespace-nowrap rounded-full bg-white px-3 py-1"
        >
          {tag}
        </span>
      ))}
    </div>
  </div>
);
