import { Lang } from "@/i18n-config";

export const getScoreTermsLabel = (lang: Lang) =>
  ({
    zh: {
      value: {
        leftIndicator: ["價值低", "股價昂貴"],
        rightIndicator: ["價值高", "股價便宜"],
        leftTitle: "價值分數越低",
        rightTitle: "價值分數越高",
        leftItems: [
          "財報獲利較沒有成長性且不穩定",
          "安全性差",
          "價格在相對貴的位置",
        ],
        rightItems: [
          "獲利比較有成長性且穩定",
          "安全性佳",
          "價格在相對便宜的位置",
        ],
      },
      trend: {
        leftIndicator: ["強勁空頭"],
        rightIndicator: ["強勁多頭"],
        leftTitle: "趨勢分數越低",
        rightTitle: "趨勢分數越高",
        leftItems: ["屬於空頭格局", "向下動能較強", "價格突破盤整向下"],
        rightItems: ["屬於多頭時機", "向上動能較強", "價格突破盤整向上"],
      },
      swing: {
        leftIndicator: ["股價高", "上漲動能弱"],
        rightIndicator: ["股價低", "上漲動能強"],
        leftTitle: "波段分數越低",
        rightTitle: "波段分數越高",
        leftItems: ["股價位置較高", "走勢轉空", "波動相對較大"],
        rightItems: ["股價位置較低", "走勢轉多", "波動相對較小"],
      },
      chip: {
        leftIndicator: ["機構資金", "看空"],
        rightIndicator: ["機構資金", "看多"],
        leftTitle: "籌碼分數越低",
        rightTitle: "籌碼分數越高",
        leftItems: ["機構持有率低", "機構較多減碼投資", "資金持續流出"],
        rightItems: ["機構持有率高", "機構較多加碼投資", "資金持續流入"],
      },
      dividend: {
        leftIndicator: ["不適合存股"],
        rightIndicator: ["適合存股"],
        leftTitle: "股利分數越低",
        rightTitle: "股利分數越高",
        leftItems: ["低殖利率", "低填息率", "配發股利不連續"],
        rightItems: ["高殖利率", "高填息率", "長期連續配發股息"],
      },
    },
    en: {
      value: {
        leftIndicator: ["Overvalued"],
        rightIndicator: ["Undervalued"],
        leftTitle: "Lower Value Score",
        rightTitle: "Higher Value Score",
        leftItems: [
          "Limited and unstable profit growth.",
          "Weak financial safety.",
          "Price at a relatively expensive level.",
        ],
        rightItems: [
          "Strong and stable profit growth.",
          "Excellent financial safety.",
          "Price at a relatively inexpensive level.",
        ],
      },
      trend: {
        leftIndicator: ["Strong Downtrend"],
        rightIndicator: ["Strong Uptrend"],
        leftTitle: "Lower Trend Score",
        rightTitle: "Higher Trend Score",
        leftItems: [
          "Bearish downtrend signal.",
          "Strong downward momentum.",
          "Price breaks consolidation and trends downward.",
        ],
        rightItems: [
          "Bullish uptrend signal.",
          "Strong upward momentum.",
          "Price breaks consolidation and trends upward.",
        ],
      },
      swing: {
        leftIndicator: ["Low upward", "Momentum"],
        rightIndicator: ["High upward", "Momentum"],
        leftTitle: "Lower Swing Trading Score",
        rightTitle: "Higher Swing Trading Score",
        leftItems: [
          "Stock price at a relative swing high.",
          "Downtrend resumes.",
          "Volatility is relatively high.",
        ],
        rightItems: [
          "Stock price at a relative swing low.",
          "Uptrend resumes.",
          "Volatility is relatively low.",
        ],
      },
      chip: {
        leftIndicator: ["Whale Avoided"],
        rightIndicator: ["Whale Preferred"],
        leftTitle: "Lower Whale Interest Score",
        rightTitle: "Higher Whale Interest Score",
        leftItems: [
          "Institutional holding rate is low.",
          "Institutions reducing holdings.",
          "Funds flowing out continuously.",
        ],
        rightItems: [
          "Institutional holding rate is high.",
          "Institutions increasing holdings.",
          "Funds flowing in continuously.",
        ],
      },
      dividend: {
        leftIndicator: ["Not Ideal for", "Buy and Hold"],
        rightIndicator: ["Ideal for", "Buy and Hold"],
        leftTitle: "Lower Dividend Score",
        rightTitle: "Higher Dividend Score",
        leftItems: [
          "Low dividend yield.",
          "Low interest payout ratio.",
          "Irregular dividend distribution.",
        ],
        rightItems: [
          "High dividend yield.",
          "High interest payout ratio.",
          "Consistent dividend distribution.",
        ],
      },
    },
  }[lang]);
