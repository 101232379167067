import { Translation } from "@/data/lang";

import { links } from "../links";

export type Oriented = "chip" | "trend" | "dividend" | "swing" | "value";
export type OrientedUrl =
  | "value"
  | "trend"
  | "swing-trading"
  | "whale-interest"
  | "dividend";

export const mapOrientedToName = ({
  t,
}: {
  t: Translation;
}): Record<Oriented, string> => ({
  value: t.analysis.shared.oriented.value,
  trend: t.analysis.shared.oriented.trend,
  swing: t.analysis.shared.oriented.swing,
  chip: t.analysis.shared.oriented.chip,
  dividend: t.analysis.shared.oriented.dividend,
});

export const mapNameToOriented = (t: Translation): Record<string, Oriented> =>
  ({
    [t.analysis.shared.oriented.value]: "value",
    [t.analysis.shared.oriented.trend]: "trend",
    [t.analysis.shared.oriented.swing]: "swing",
    [t.analysis.shared.oriented.chip]: "chip",
    [t.analysis.shared.oriented.dividend]: "dividend",
  } as const);

export const urlOrientedMap: Record<OrientedUrl, Oriented> = {
  value: "value",
  trend: "trend",
  "swing-trading": "swing",
  "whale-interest": "chip",
  dividend: "dividend",
};

export const orientedUrlMap: Record<Oriented, string> = {
  value: "value",
  trend: "trend",
  swing: "swing-trading",
  chip: "whale-interest",
  dividend: "dividend",
};

export const indexOrientedMap: Record<number, Oriented> = {
  0: "value",
  1: "trend",
  2: "swing",
  3: "chip",
  4: "dividend",
} as const;

export const orientedLinkMap: Map<
  Oriented,
  ({ symbol }: { symbol: string }) => string
> = new Map([
  ["value", links.myAnalysisValue],
  ["trend", links.myAnalysisTrend],
  ["swing", links.myAnalysisSwingTrading],
  ["chip", links.myAnalysisWhaleInterest],
  ["dividend", links.myAnalysisDividend],
]);

export const urlIndexMap: Record<OrientedUrl, number> = {
  value: 0,
  trend: 1,
  "swing-trading": 2,
  "whale-interest": 3,
  dividend: 4,
};
